/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NotificationService } from '../notification/notification.service';
import { Storage } from '@ionic/storage-angular';
import { UserService } from '../user/user.service';
import { StepsPage } from 'src/app/pages/dashboard/modals/steps/steps.page';
import { ToastService } from 'src/app/utils/toastService';
import { USER, TOKEN, LOADING_ICON, SPLASH, PULSE_DOCUMENTS, PULSE_ADDRESSES, PULSE_SERVICES, PULSE_INSURANCES, PULSE_PAYMENT_CARDS, PULSE_SCHEDULES, SHOW_STEPS, FIRST_USE, THEME } from 'src/main';
import { PlansPage } from 'src/app/pages/dashboard/modals/plans/plans.page';
import { BlockedPage } from 'src/app/pages/dashboard/modals/blocked/blocked.page';
import { AnnuityPage } from 'src/app/pages/dashboard/modals/annuity/annuity.page';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public toastService: ToastService,
    public modalController: ModalController,
    private serviceNotification: NotificationService,
    private userService: UserService,
    private storage: Storage,
  ) {
    this.platform.ready().then(() => {
      this.storage.create();
      this.ifLoggedIn();
    });
  }

  async ifLoggedIn(isRefresh = false) {
    this.storage.get(USER).then(async (user) => {
      if (user) {
        this.storage.get(TOKEN).then(async (token) => {
          if (token) {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            this.userService.refresh(token).subscribe((res: any) => {
              loading.dismiss();
              if (res.status === 201) {
                if (res.body.user) {
                  this.authState.next(true);
                  this.storage.set(USER, res.body.user);
                  this.verifyPage(res.body.user, isRefresh);
                } else {
                  this.toastService.sendMessage(200, 'Seu login expirou');
                  this.logout();
                }
              } else {
                this.toastService.sendMessage(res.status, res.body.message);
                this.logout();
              }
            }, err => {
              loading.dismiss();
              this.toastService.sendMessage(err.status, err.error.message);
              this.logout();
            });
          } else {
            this.logout();
          }
        });
      } else {
        this.navCtrl.navigateRoot(['login']);
        this.storage.set(SPLASH, true);
      }
    });
  }

  async login(data: any) {
    this.storage.remove(SPLASH);
    this.authState.next(true);
    this.storage.set(TOKEN, data.access_token);
    this.storage.set(USER, data.user);
    this.verifyPage(data.user, false);
  }

  verifyPage(data: any, isRefresh = false) {
    if (this.platform.is('cordova')) {
      this.serviceNotification.start(data.id);
    }

    if (data.professional) {
      if (data.professional.is_verified && data.professional.active) {
        if (!isRefresh) {
          this.navCtrl.navigateRoot(['/tabs/home']);
        }
      } else {
        if (data.professional.documents.length === 0) {
          this.storage.set(PULSE_DOCUMENTS, true);
        }
        if (data.professional.addresses.length === 0) {
          this.storage.set(PULSE_ADDRESSES, true);
        }
        if (data.professional.services.length === 0) {
          this.storage.set(PULSE_SERVICES, true);
        }
        if (data.professional.insurances.length === 0) {
          this.storage.set(PULSE_INSURANCES, true);
        }
        if (data.professional.payment_types.length === 0) {
          this.storage.set(PULSE_PAYMENT_CARDS, true);
        }
        if (data.professional.timelines.length === 0) {
          this.storage.set(PULSE_SCHEDULES, true);
        }

        this.navCtrl.navigateRoot(['/tabs/profile']);

        if (!data.professional.active) {
          this.showInactive();
        } else if (!isRefresh) {
          this.showSteps();
        }
      }

      if (!data.professional.establishment) {
        if (!data.professional.last_payment_plan) {
          this.showPlans();
        } else if (!data.professional.establishment && data.professional.last_payment_plan.active === 3) {
          this.showRenewPlan();
        }
      }
    } else {
      this.logout();
    }
  }

  async showSteps() {
    this.storage.get(SHOW_STEPS).then(async (data) => {
      if (!data) {
        setTimeout(async () => {
          const modal = await this.modalController.create({
            component: StepsPage,
            mode: 'ios',
            swipeToClose: true,
            cssClass: 'steps-modal',
            componentProps: {
              data: {}
            }
          });

          return await modal.present();
        }, 1000);
      }
    });
  }

  async showPlans() {
    setTimeout(async () => {
      const modal = await this.modalController.create({
        component: PlansPage,
        mode: 'ios',
        swipeToClose: true,
        cssClass: 'steps-modal',
        componentProps: {
          data: {}
        }
      });

      return await modal.present();
    }, 1000);
  }

  async showRenewPlan() {
    setTimeout(async () => {
      const modal = await this.modalController.create({
        component: AnnuityPage,
        mode: 'ios',
        swipeToClose: true,
        cssClass: 'steps-modal',
        componentProps: {
          data: {}
        }
      });

      return await modal.present();
    }, 1000);
  }

  async showInactive() {
    setTimeout(async () => {
      const modal = await this.modalController.create({
        component: BlockedPage,
        mode: 'ios',
        swipeToClose: true,
        cssClass: 'steps-modal',
        componentProps: {
          data: {}
        }
      });

      return await modal.present();
    }, 1000);
  }

  async logout() {
    if (this.platform.is('cordova')) {
      this.serviceNotification.end();
    }

    this.storage.get(THEME).then(async (themeStorage) => {
      this.storage.set(THEME, themeStorage);
    });

    this.storage.clear();
    this.storage.set(FIRST_USE, true);
    this.authState.next(false);
    this.navCtrl.navigateRoot(['/login']);
  }

  isAuthenticated() {
    return this.authState.value;
  }

}
