/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

const PATH = 'professional/payment-plans';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  getPaymentPlans(token: string) {
    return this.http.get(API + PATH + '/list', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  create(content: any, token: string) {
    return this.http.post(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  update(content: any, token: string) {
    return this.http.post(API + PATH + '/change', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  getCallTimeProducts(token: string) {
    return this.http.get(API + PATH + '/call-time-products', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  buyCallTime(content: any, token: string) {
    return this.http.post(API + PATH + '/buy-call-time', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  getServiceContract(token: string) {
    return this.http.get(API + PATH + '/contract', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }
}
