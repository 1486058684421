import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.page.html',
  styleUrls: ['./invoice-history.page.scss'],
})
export class InvoiceHistoryPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
