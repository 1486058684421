import { Component, OnInit } from '@angular/core';
import { PaymentPlanService } from 'src/app/services/payment-plan/payment-plan.service';
import { ToastService } from 'src/app/utils/toastService';
import { TOKEN } from 'src/main';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-service-contract',
  templateUrl: './service-contract.page.html',
  styleUrls: ['./service-contract.page.scss'],
})
export class ServiceContractPage implements OnInit {
  loading = false;
  contract = '';

  constructor(
    public toastService: ToastService,
    private storage: Storage,
    private paymentPlanService: PaymentPlanService
  ) { }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.loading = true;
    this.storage.get(TOKEN).then(async (token) => {
      this.paymentPlanService.getServiceContract(token).subscribe((res: any) => {
        this.loading = false;
        if (res.status === 201) {
          this.contract = res.body;
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

}
