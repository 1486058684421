/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { TOKEN, USER } from 'src/main';
import { Storage } from '@ionic/storage-angular';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { PaymentPlanService } from 'src/app/services/payment-plan/payment-plan.service';
import { ToastService } from 'src/app/utils/toastService';
import { ChangePlanPage } from '../change-plan/change-plan.page';

@Component({
  selector: 'app-annuity',
  templateUrl: './annuity.page.html',
  styleUrls: ['./annuity.page.scss'],
})
export class AnnuityPage implements OnInit {
  loading = false;

  plans = [];

  actualPlan = '';
  selectedPlan = '';
  selectedPaymentType = 'pix';

  planIsActive = -1;
  endDate = '';

  constructor(
    public toastService: ToastService,
    private playmentPlanService: PaymentPlanService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.refreshUser();
    this.loadPage();
  }

  loadPage() {
    this.loading = true;
    this.storage.get(TOKEN).then(async (token) => {
      this.playmentPlanService.getPaymentPlans(token).subscribe((res: any) => {
        this.loading = false;
        if (res.status === 201) {
          this.plans = res.body;
          this.refreshUser();
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async setPayment() {
    if (this.selectedPlan === this.actualPlan) {
      this.toastService.sendMessage(200, 'Selecione um plano diferente do atual para prosseguir');
      return;
    }

    let plan = null;

    this.plans.forEach(element => {
      if (element.id === this.selectedPlan) {
        plan = element;
      }
    });

    if (!plan) {
      this.toastService.sendMessage(200, 'Selecione um plano para prosseguir');
    }

    const modal = await this.modalController.create({
      component: ChangePlanPage,
      mode: 'ios',
      swipeToClose: true,
      cssClass: 'steps-modal background-shadow',
      componentProps: {
        selectedPlan: plan.id,
        planMembershipFee: plan.membership_fee,
        planMonthsLong: plan.months_long,
        planName: plan.name,
        planPrice: plan.price,
        planTotal: plan.total
      }
    });

    modal.onDidDismiss().then((content) => {
      const res = content.data;
      this.refreshUser();
      if (res !== undefined) {
        this.modalController.dismiss(true);
      }
    });

    return await modal.present();
  }

  refreshUser() {
    this.storage.get(USER).then(async (user) => {
      if (user.professional.last_payment_plan) {
        this.actualPlan = user.professional.last_payment_plan.category_hiring_plan;
        this.planIsActive = user.professional.last_payment_plan.active;
        this.endDate = user.professional.last_payment_plan.end_date;
      }

      this.plans.forEach(element => {
        if (element.id !== this.actualPlan) {
          this.selectedPlan = element.id;
        }
      });
    });
  }
}
