/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { AlertController, IonRouterOutlet, LoadingController, ModalController, Platform } from '@ionic/angular';
import { ToastService } from 'src/app/utils/toastService';
import { DOCUMENT_CARD, DOCUMENT_NF, LOADING_ICON, TOKEN, USER } from 'src/main';
import { Storage } from '@ionic/storage-angular';
import { PaymentPlanService } from 'src/app/services/payment-plan/payment-plan.service';
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { environment } from 'src/environments/environment';
import { ValidatorService } from 'src/app/utils/ValidatorService';
import { NewCreditCardPage } from '../../profile/my-credit-cards/new-credit-card/new-credit-card.page';
import { ServiceContractPage } from '../service-contract/service-contract.page';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.page.html',
  styleUrls: ['./plans.page.scss'],
})
export class PlansPage implements OnInit {
  loading = false;
  segment = '1';
  isIos = false;
  sameNfDocument = false;
  checkContract = false;

  plans = [];

  cards = [];
  paymentMethod = 'DEBIT';
  installments = '1';
  selectedPlan = '';
  selectedCard = '';
  planMembershipFee = '';
  planMonthsLong = '';
  planName = '';
  planPrice = '';
  planTotal = '';
  cvv = '';
  document = '';
  nfDocument = '';

  card = {
    brand: '',
    last_numbers: '',
    expire: ''
  };

  products: IAPProduct[] = [];

  constructor(
    public toastService: ToastService,
    private paymentPlanService: PaymentPlanService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private storage: Storage,
    public platform: Platform,
    private store: InAppPurchase2,
    private ref: ChangeDetectorRef,
    private validatorService: ValidatorService,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
    platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.isIos = true;

        if (!environment.production) {
          this.store.verbosity = this.store.DEBUG;
        }

        this.registerProducts();
        this.setupListeners();

        this.store.ready(() => {
          this.products = [];
          this.products = this.store.products;
          console.log(this.products);
          this.ref.detectChanges();
        });
      }
    });
  }

  ngOnInit() {
    this.refreshCards();
    this.loadPage();
  }

  loadPage() {
    this.loading = true;
    this.storage.get(USER).then(async (user) => {
      this.storage.get(TOKEN).then(async (token) => {
        this.paymentPlanService.getPaymentPlans(token).subscribe((res: any) => {
          this.loading = false;
          if (res.status === 201) {
            this.plans = res.body;

            if (this.plans.length > 0) {
              this.setPlan(
                this.plans[0].id,
                this.plans[0].membership_fee,
                this.plans[0].months_long,
                this.plans[0].name,
                this.plans[0].price,
                this.plans[0].total
              );
            }
          } else {
            this.toastService.sendMessage(res.status, res.body.message);
          }
        }, err => {
          this.loading = false;
          this.toastService.sendMessage(err.status, err.error.message);
        });
      });
    });
  }

  verify() {
    if (!this.selectedPlan) {
      this.toastService.sendMessage(200, 'Você precisa selecionar um plano de contratação');
      return;
    }

    if (!this.selectedCard) {
      this.toastService.sendMessage(200, 'Você precisa informar todos os dados de pagamento');
      return;
    }

    if (!this.document || !this.validatorService.verifyCpf(this.document)) {
      this.toastService.sendMessage(200, 'O documento do titular é inválido');
      return;
    }

    if (!this.nfDocument || !this.validatorService.verifyCpf(this.nfDocument)) {
      this.toastService.sendMessage(200, 'O documento para a nota fiscal é inválido');
      return;
    }

    if (!this.cvv) {
      this.toastService.sendMessage(200, 'Digite o CVV do cartão selecionado');
      return;
    }

    if (!this.checkContract) {
      this.toastService.sendMessage(200, 'É preciso aceitar os termos do contrato de prestação de serviços para continuar');
      return;
    }

    this.send();
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Você tem certeza que deseja contratar este plano?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            const content = {
              plan: this.selectedPlan,
              credit_card: this.selectedCard,
              document: this.document,
              cvv: this.cvv,
              installments: this.installments,
              nf_document: this.nfDocument,
              payment_method: this.paymentMethod
            };

            this.storage.get(TOKEN).then(async (token) => {
              this.paymentPlanService.create(content, token).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);
                if (res.status === 201) {
                  this.modalController.dismiss(true);
                  this.storage.set(USER, res.body.user);
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  registerProducts() {
    this.store.register({
      id: '0002',
      type: this.store.PAID_SUBSCRIPTION
    });

    this.store.when('0002').registered((product: IAPProduct) => {
      console.log('Registered: ' + JSON.stringify(product));
    });

    this.store.refresh();
  }

  setupListeners() {
    this.store.when('product').approved((p: IAPProduct) => {
      this.toastService.sendMessage(200, 'Plano contratado com sucesso');
      this.ref.detectChanges();
      return p.verify();
    }).verified((p: IAPProduct) => {
      p.finish();
    });

    this.store.when('product').owned((p: IAPProduct) => {
      this.toastService.sendMessage(200, 'Você já possui um plano');
    });
  }

  purchase(product: IAPProduct) {
    this.store.order(product).then((p) => {
      this.toastService.sendMessage(200, 'Compra realizada com sucesso');
    }, err => {
      this.toastService.sendMessage(405, 'Falha ao efetuar compra');
      console.log(err);
    });
  }

  restore() {
    this.store.refresh();
  }

  next(segment: string) {
    if (this.segment === '1') {
      if (!this.selectedPlan) {
        this.toastService.sendMessage(200, 'É necessário selecionar um plano para prosseguir');
        return;
      }

    } else if (this.segment === '2') {
      if (!this.selectedCard) {
        this.toastService.sendMessage(200, 'É necessário selecionar um cartão');
        return;
      }

      this.storage.get(DOCUMENT_CARD).then((card) => {
        this.document = card;
      });

      this.storage.get(DOCUMENT_NF).then((nf) => {
        this.nfDocument = nf;
      });
    } else if (this.segment === '3') {
      if (!this.document || !this.validatorService.verifyCpf(this.document)) {
        this.toastService.sendMessage(200, 'O documento do titular é inválido');
        return;
      }

      if (!this.nfDocument || !this.validatorService.verifyCpf(this.nfDocument)) {
        this.toastService.sendMessage(200, 'O documento para a nota fiscal é inválido');
        return;
      }

      if (!this.cvv) {
        this.toastService.sendMessage(200, 'É necessário informar o cvv para prosseguir');
        return;
      }

      if (!this.installments) {
        this.toastService.sendMessage(200, 'É necessário selecionar o parcelamento para prosseguir');
        return;
      }

      this.storage.set(DOCUMENT_CARD, this.document);
      this.storage.set(DOCUMENT_NF, this.nfDocument);
    }

    this.segment = segment;
  }

  async openNewCardModal() {
    const modal = await this.modalController.create({
      component: NewCreditCardPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((content) => {
      if (content.data !== undefined) {
        this.refreshCards();
      }
    });

    return await modal.present();
  }

  changePlan() {
    this.plans.forEach(element => {
      if (element.id === this.selectedPlan) {
        this.setPlan(element.id, element.membership_fee, element.months_long, element.name, element.price, element.total);
      }
    });
  }

  setPlan(id, planMembershipFee, planMonthsLong, planName, planPrice, planTotal) {
    this.selectedPlan = id;
    this.planMembershipFee = planMembershipFee;
    this.planMonthsLong = planMonthsLong;
    this.planName = planName;
    this.planPrice = planPrice;
    this.planTotal = planTotal;
  }

  back(segment: string) {
    this.segment = segment;
  }

  changeNfDocument(event: any) {
    if (!event.detail.checked) {
      this.nfDocument = '';
    } else {
      this.nfDocument = this.document;
    }
  }

  setCard() {
    this.cards.forEach(element => {
      if (element.hash === this.selectedCard) {
        this.card.brand = element.brand;
        this.card.last_numbers = element.last_numbers;
        this.card.expire = element.expire;
      }
    });
  }

  refreshCards() {
    this.storage.get(USER).then(async (user) => {
      this.cards = [];

      let count = 0;
      user.credit_cards.forEach(element => {

        if (count === 0) {
          this.selectedCard = element.hash;
          this.setCard();
        }

        this.cards.push(element);
        count++;
      });

    });
  }

  changePaymentMethod(ev: any) {
    if (ev.detail.value === 'DEBIT') {
      this.installments = '1';
    }
  }

  async setModal(page: number) {
    let componentModal = null;

    if (page === 1) {
      componentModal = ServiceContractPage;
    }

    const modal = await this.modalController.create({
      component: componentModal,
      mode: 'ios',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((content) => {
      const data = content.data;

      if (data !== undefined) {

      }
    });

    return await modal.present();
  }
}
