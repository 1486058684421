/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.page.html',
  styleUrls: ['./news-details.page.scss'],
})
export class NewsDetailsPage implements OnInit {

  data = {
    content: '',
    cover: '',
    publish_date: '',
    subtitle: '',
    title: ''
  };

  constructor() { }

  ngOnInit() {
  }

}
