/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Optional } from '@angular/core';
import { LoadingController, AlertController, ModalController, IonRouterOutlet } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ChangeAboutPage } from '../../modals/change-about/change-about.page';
import { AnnuityPage } from '../../modals/annuity/annuity.page';
import { SeeMyProfilePage } from '../../modals/see-my-profile/see-my-profile.page';
import { ToastService } from 'src/app/utils/toastService';
import { InvoiceHistoryPage } from '../../modals/invoice-history/invoice-history.page';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { USER, TOKEN } from 'src/main';
import { ChangeContactMeansPage } from '../../modals/change-contact-means/change-contact-means.page';
import { ChangeProfessionalEmailsPage } from '../../modals/change-professional-emails/change-professional-emails.page';
import { SetAttendanceRadiusPage } from '../../modals/set-attendance-radius/set-attendance-radius.page';

@Component({
  selector: 'app-edit-professional-profile',
  templateUrl: './edit-professional-profile.page.html',
  styleUrls: ['./edit-professional-profile.page.scss'],
})
export class EditProfessionalProfilePage implements OnInit {
  loading = false;
  user = {
    image_url: '',
    professional: {
      attendance_radius: '',
      addresses: [],
      show_profile: false,
      receive_service_mails: false,
      receive_promotional_mails: false,
      is_verified: false,
      last_payment_plan: {
        active: 0
      },
      establishmentData: {
        cnpj: '',
        name: '',
        last_name: '',
        city: '',
        state: '',
        profile_picture: ''
      }
    }
  };

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private serviceProfile: ProfileService,
    private toastService: ToastService,
    private storage: Storage,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
    this.refreshUser();
  }

  ngOnInit() {
  }

  refreshUser() {
    this.storage.get(USER).then(async (user) => {
      this.user = user;
    });
  }

  async setModal(page: number) {
    let componentModal = null;
    if (page === 1) {
      componentModal = SeeMyProfilePage;
    } else if (page === 2) {
      componentModal = ChangeAboutPage;
    } else if (page === 3) {
      componentModal = ChangeContactMeansPage;
    } else if (page === 4) {
      componentModal = ChangeProfessionalEmailsPage;
    } else if (page === 5) {
      componentModal = AnnuityPage;
    } else if (page === 6) {
      componentModal = InvoiceHistoryPage;
    } else if (page === 7) {
      componentModal = SetAttendanceRadiusPage;
    }

    const modal = await this.modalController.create({
      component: componentModal,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        data: {}
      }
    });

    modal.onDidDismiss()
      .then((content) => {
        const data = content.data;

        if (data !== undefined) {
          this.refreshUser();
        }
      });

    return await modal.present();
  }

}
