/* eslint-disable max-len */
import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { LoadingController, AlertController, ModalController, IonInfiniteScroll, ActionSheetController, IonRouterOutlet, Platform } from '@ionic/angular';
import { SuggestInsurancesPage } from '../../modals/suggest-insurances/suggest-insurances.page';
import { ToastService } from 'src/app/utils/toastService';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';
import { Storage } from '@ionic/storage-angular';
import { TOKEN, LOADING_ICON, HIDE_PULSE_INSURANCES, PULSE_INSURANCES, USER } from 'src/main';
import { Camera, CameraOptions, PictureSourceType } from '@awesome-cordova-plugins/camera/ngx';

@Component({
  selector: 'app-list-insurance',
  templateUrl: './list-insurance.page.html',
  styleUrls: ['./list-insurance.page.scss'],
})
export class ListInsurancePage implements OnInit {
  @ViewChild('IonInfiniteScroll', { read: IonInfiniteScroll, static: true }) infiniteScroll: IonInfiniteScroll;
  loading = false;
  pageNo = 1;
  segment = '1';
  showMessage = false;

  insurances = [];
  selectedInsurances = [];
  tempSelectedInsurances = [];
  searchTerm = '';
  isCordova = true;

  havePlan = -1;

  constructor(
    private camera: Camera,
    public toastService: ToastService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private actionSheetController: ActionSheetController,
    private serviceInsurance: InsuranceService,
    private platform: Platform,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }

    this.storage.get(USER).then(async (user) => {
      if (user.professional.last_payment_plan) {
        this.havePlan = user.professional.last_payment_plan.active;
      }
    });
  }

  ngOnInit() {
    this.verifyMessage();
    this.loadPage(false);
  }

  loadPage(infinite: boolean) {
    if (!infinite) {
      this.loading = true;
      this.pageNo = 1;
      this.insurances = [];
      this.selectedInsurances = [];
      this.tempSelectedInsurances = [];
      if (this.infiniteScroll) {
        this.infiniteScroll.disabled = false;
      }
    }

    this.storage.get(TOKEN).then((token) => {
      this.serviceInsurance.getMyInsurances(token, this.searchTerm, this.pageNo).subscribe((res: any) => {
        this.loading = false;
        console.log(res.body);
        if (res.status === 201) {
          if (infinite) { this.infiniteScroll.complete(); }

          if (res.body.length === 0) {
            this.infiniteScroll.disabled = true;
          } else {
            this.pageNo++;

            res.body.insurances.forEach(element => {
              this.insurances.push(element);
            });

            res.body.selectedInsurances.forEach(element => {
              this.selectedInsurances.push(element);
              this.tempSelectedInsurances.push(element);
            });
          }
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        if (infinite) { this.infiniteScroll.complete(); }
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async selectImage(item: any) {
    if (this.havePlan === -1) {
      this.toastService.sendMessage(200, 'Você precisa selecionar um plano de contratação para prosseguir');
      return;
    } else if (this.havePlan === 0) {
      this.toastService.sendMessage(200, 'Aguarde a confirmação de pagamento pelo nosso sistema');
      return;
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'Selecione o local da imagem',
      cssClass: 'my-action-sheet',
      buttons: [{
        text: 'Galeria',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Camera',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancelar',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();
  }

  async takePicture(item: any, sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 80,
      sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
    };

    this.camera.getPicture(options).then((imageData) => {
      item.document = 'data:image/jpeg;base64,' + imageData;
      item.changed = true;
    });
  }

  setBrowserFile(item: any, event: any) {
    if (this.havePlan === -1) {
      this.toastService.sendMessage(200, 'Você precisa selecionar um plano de contratação para prosseguir');
      return;
    } else if (this.havePlan === 0) {
      this.toastService.sendMessage(200, 'Aguarde a confirmação de pagamento pelo nosso sistema');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (): void => {
      item.document = reader.result;
      item.changed = true;
    };
    reader.onerror = (error): void => {
      console.log('Error: ', error);
      this.toastService.sendMessage(200, 'Falha ao selecionar imagem');
    };
  }

  async save(item: any) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja enviar este documento para aprovação?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            const dataSend = {
              id: item.id,
              document: item.document
            };

            this.storage.get(TOKEN).then((token) => {
              this.serviceInsurance.updateInsurance(token, dataSend).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.loadPage(false);
                  this.hide();
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async changeStatus(item: any) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja alterar a disponibilidade deste convênio?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            const dataSend = {
              id: item.id
            };

            this.storage.get(TOKEN).then((token) => {
              this.serviceInsurance.changeStatus(token, dataSend).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  item.active = !item.active;
                  this.hide();
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  verifyMessage() {
    this.storage.get(HIDE_PULSE_INSURANCES).then(async (data) => {
      if (!data) {
        this.storage.get(PULSE_INSURANCES).then(async (data2) => {
          if (data2) {
            this.showMessage = true;
          }
        });
      }
    });
  }

  hide() {
    this.storage.remove(PULSE_INSURANCES);
    this.storage.set(HIDE_PULSE_INSURANCES, true);
    this.showMessage = false;
  }

  async search() {
    if (this.searchTerm.length > 4 && !this.loading) {
      this.loadPage(false);
    }
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: SuggestInsurancesPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        data: {}
      }
    });

    modal.onDidDismiss()
      .then((content) => {
        const data = content.data;

        if (data !== undefined) {

        }
      });

    return await modal.present();
  }

  refresh(event) {
    this.loading = true;
    setTimeout(() => {
      this.loadPage(false);
      event.target.complete();
    }, 1500);
  }

  doInfinite() {
    this.loadPage(true);
  }

}
