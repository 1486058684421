/* eslint-disable id-blacklist */
import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { CreditCardService } from 'src/app/services/credit-card/credit-card.service';
import { ValidatorService } from 'src/app/utils/ValidatorService';
import { ToastService } from 'src/app/utils/toastService';
import { LOADING_ICON, TOKEN, USER } from 'src/main';
import creditCardType from 'credit-card-type';

@Component({
  selector: 'app-new-credit-card',
  templateUrl: './new-credit-card.page.html',
  styleUrls: ['./new-credit-card.page.scss'],
})
export class NewCreditCardPage implements OnInit {
  loading = false;

  card = {
    brand: '',
    number: '',
    name: '',
    expire: '',
    cvv: '',
    document: ''
  };

  constructor(
    public toastService: ToastService,
    public modalController: ModalController,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private storage: Storage,
    private serviceCreditCard: CreditCardService,
    private validatorService: ValidatorService
  ) { }

  ngOnInit() {
  }

  verify() {
    if (!this.card.brand) {
      this.toastService.sendMessage(200, 'O cartão digitado não foi reconhecido');
      return;
    }

    if (!this.card.number || this.card.number.length < 17) {
      this.toastService.sendMessage(200, 'O número do cartão precisa conter ao menos 14 digitos');
      return;
    }

    if (!this.card.name || !this.card.expire || !this.card.cvv || !this.card.document) {
      this.toastService.sendMessage(200, 'Você precisa informar todos os dados do cartão');
      return;
    }

    if (this.validatorService.verifyCpf(this.card.document)) {
      this.send();
    } else {
      this.toastService.sendMessage(200, 'O CPF informado é inválido');
    }
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja cadastrar este cartão?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();
            this.loading = true;

            this.storage.get(TOKEN).then((token) => {
              this.serviceCreditCard.create(token, this.card).subscribe((res: any) => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.storage.set(USER, res.body.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  setCardBrand() {
    const bandeiraCartao = creditCardType(this.card.number);

    if (bandeiraCartao && bandeiraCartao.length > 0 && this.card.number.length > 2) {
      this.card.brand = bandeiraCartao[0].niceType;
    } else {
      this.card.brand = '';
    }
  }
}
