/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/utils/toastService';
import { Storage } from '@ionic/storage-angular';
import { TOKEN, USER } from 'src/main';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-change-contact-means',
  templateUrl: './change-contact-means.page.html',
  styleUrls: ['./change-contact-means.page.scss'],
})
export class ChangeContactMeansPage implements OnInit {
  loading = false;
  user = {
    professional: {
      contact_means: {
      }
    }
  };

  data: {
    email: '';
    whatsapp: '';
    telegram: '';
    phone: '';
    telephone: '';
  };

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    private toastService: ToastService,
    private serviceProfile: ProfileService,
    public modalController: ModalController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.storage.get(USER).then(async (user) => {
      this.user = user;
      if (user.professional.contact_means) {
        this.data = user.professional.contact_means;
      }
    });
  }

  async save() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja alterar os meios de contato?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            this.storage.get(TOKEN).then((token) => {
              this.serviceProfile.updateContactMeans(token, this.data).subscribe((res: any) => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.user.professional.contact_means = this.data;
                  this.storage.set(USER, this.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
