/* eslint-disable id-blacklist */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestValidationCodeForm } from 'src/app/models/request-validation-code.model';
import { UserRegisterForm } from 'src/app/models/user-register-form.model';
import { EncryptionService } from 'src/app/utils/encryption.service';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private encryptionService: EncryptionService
  ) { }

  register(content: any) {
    const request = {
      name: this.encryptionService.encrypt(content.name),
      last_name: this.encryptionService.encrypt(content.last_name),
      email: this.encryptionService.encrypt(content.email),
      phone: this.encryptionService.encrypt(content.phone),
      category: this.encryptionService.encrypt(content.category),
      services: [],
      register_number: this.encryptionService.encrypt(content.register_number),
      gender: content.gender,
      confirmEmail: this.encryptionService.encrypt(content.confirmEmail),
      password: this.encryptionService.encrypt(content.password),
      confirmPassword: this.encryptionService.encrypt(content.confirmPassword),
      document_type: this.encryptionService.encrypt(content.document_type),
      type: this.encryptionService.encrypt(content.type),
      address_name: this.encryptionService.encrypt(content.address_name),
      postcode: this.encryptionService.encrypt(content.postcode),
      street: this.encryptionService.encrypt(content.street),
      number: this.encryptionService.encrypt(content.number),
      neighborhood: this.encryptionService.encrypt(content.neighborhood),
      district: this.encryptionService.encrypt(content.district),
      city: this.encryptionService.encrypt(content.city),
      uf: this.encryptionService.encrypt(content.uf),
      user_type: this.encryptionService.encrypt(content.user_type)
    } as UserRegisterForm;

    content.services.forEach(element => {
      request.services.push(
        this.encryptionService.encrypt(element)
      );
    });

    return this.http.post(API + 'auth/register', JSON.stringify(request), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  verifyExists(content: any) {
    const request = {
      name: this.encryptionService.encrypt(content.name),
      last_name: this.encryptionService.encrypt(content.last_name),
      email: this.encryptionService.encrypt(content.email),
      phone: this.encryptionService.encrypt(content.phone),
      type: this.encryptionService.encrypt(content.type),
      user_type: this.encryptionService.encrypt(content.user_type),
      from_where: this.encryptionService.encrypt(content.from_where)
    };

    return this.http.post(API + 'auth/verify-exists', JSON.stringify(request), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  verifyAddress(content: any) {
    const request = {
      postcode: this.encryptionService.encrypt(content.postcode),
      street: this.encryptionService.encrypt(content.street),
      number: this.encryptionService.encrypt(content.number),
      district: this.encryptionService.encrypt(content.district),
      city: this.encryptionService.encrypt(content.city),
      uf: this.encryptionService.encrypt(content.uf)
    };

    return this.http.post(API + 'auth/verify-address', JSON.stringify(request), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  login(content: any) {
    return this.http.post(API + 'auth/login-professional', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  refresh(token: string) {
    return this.http.get(API + 'auth/refresh', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  logout(token: string) {
    return this.http.get(API + 'auth/logout', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  requestValidationCode(content: any) {

    const request = {
      email: this.encryptionService.encrypt(content.email),
      password: this.encryptionService.encrypt(content.password),
      type: this.encryptionService.encrypt(content.type)
    } as RequestValidationCodeForm;

    return this.http.post(API + 'auth/request-validation-code', JSON.stringify(request), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  requestResetPasswordCode(content: any) {
    return this.http.post(API + 'auth/request-reset-password-code', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  resetPassword(content: any) {
    return this.http.post(API + 'auth/reset-password', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  updatePassword(content: any) {
    return this.http.put(API + 'update-password', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

}
