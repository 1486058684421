/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { USER } from 'src/main';

@Component({
  selector: 'app-blocked',
  templateUrl: './blocked.page.html',
  styleUrls: ['./blocked.page.scss'],
})
export class BlockedPage implements OnInit {

  user = {
    name: '',
    professional: {
      active: '',
    },
    last_block: {
      reason: ''
    }
  };

  constructor(
    private storage: Storage
  ) {
    this.storage.get(USER).then((user) => {
      this.user = user;
    });
  }

  ngOnInit() {
  }

}
