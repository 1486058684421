/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable id-blacklist */
import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ToastService } from 'src/app/utils/toastService';
import { AddressService } from 'src/app/services/address/address.service';
import { USER, LOADING_ICON, TOKEN, PULSE_ADDRESSES, HIDE_PULSE_ADDRESSES } from 'src/main';
import { Camera, CameraOptions, PictureSourceType } from '@awesome-cordova-plugins/camera/ngx';

@Component({
  selector: 'app-add-locality',
  templateUrl: './add-locality.page.html',
  styleUrls: ['./add-locality.page.scss'],
})
export class AddLocalityPage implements OnInit {
  loading = false;
  postCodeAccepted = false;

  headerTxt = 'Novo';
  btTxt = 'Salvar';
  btColor = 'success';
  documentChange = false;
  isCordova = true;

  data = {
    id: 0,
    name: '',
    postcode: '',
    address: '',
    street: '',
    number: '',
    neighborhood: '',
    district: '',
    city: '',
    uf: '',
    document: '',
    is_verified: -1
  };

  profile = {};

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private serviceAddress: AddressService,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private storage: Storage,
    private platform: Platform
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }
  }

  ngOnInit() {
    this.startPage();
  }

  startPage() {
    this.storage.get(USER).then((user) => {
      this.profile = user.professional;
      if (this.data.id > 0) {
        this.btTxt = 'Atualizar';
        this.headerTxt = 'Atualizar';
        this.btColor = 'primary';
      } else {
        this.data.is_verified = -1;
      }
    });
  }

  async save() {
    if (this.data.name) {
      if (this.data.postcode && (this.data.postcode.length === 8 || this.data.postcode.length === 9)) {
        if (this.data.number && this.data.number.length > 0) {
          const alert = await this.alertController.create({
            header: 'Confirmação',
            message: 'Tem certeza que deseja enviar este endereço para aprovação?',
            buttons: [
              {
                text: 'Não',
                role: 'cancel',
              }, {
                text: 'Sim',
                handler: async () => {
                  const loading = await this.loadingController.create({
                    message: 'Aguarde...',
                    spinner: LOADING_ICON
                  });

                  await loading.present();

                  this.storage.get(TOKEN).then((token) => {
                    if (this.data.id > 0) {
                      this.verifyUpdate(loading, token);
                    } else {
                      this.create(loading, token);
                    }
                  });
                }
              }
            ]
          });

          await alert.present();
        } else {
          this.toastService.sendMessage(200, 'Informe o número do endereço');
        }
      } else {
        this.toastService.sendMessage(200, 'Este cep é inválido');
      }
    } else {
      this.toastService.sendMessage(200, 'Informe um nome para o endereço');
    }
  }

  getApiAddressData() {
    const valor = this.data.postcode;
    const cep = valor.replace(/\D/g, '');
    if (cep.length === 8) {

      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep) && this.postCodeAccepted === false) {

        this.loading = true;
        this.serviceAddress.verifyCEP(cep).subscribe((res: any) => {
          if (res.erro) {
            this.postCodeAccepted = false;
            this.loading = false;

            this.data.street = '';
            this.data.address = '';
            this.data.district = '';
            this.data.city = '';

            this.toastService.sendMessage(200, 'CEP não encontrado');

          } else {
            this.postCodeAccepted = true;
            this.loading = false;

            this.data.street = res.logradouro;
            this.data.district = res.bairro;
            this.data.city = res.localidade;
            this.data.uf = res.uf;
            this.data.address = res.logradouro;
          }
        }, err => {
          this.loading = false;
          this.toastService.sendMessage(500, 'Tente novamente mais tarde');
        });

      } else {
        this.data.postcode = '';
        this.data.street = '';
        this.data.address = '';
        this.data.district = '';
        this.data.city = '';
        this.postCodeAccepted = false;
      }
    } else {
      this.data.street = '';
      this.data.address = '';
      this.data.district = '';
      this.data.city = '';
      this.postCodeAccepted = false;
    }
  }

  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Selecione o local da imagem',
      cssClass: 'my-action-sheet',
      buttons: [{
        text: 'Galeria',
        handler: () => {
          this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Camera',
        handler: () => {
          this.takePicture(this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancelar',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();
  }

  async takePicture(sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 90,
      sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
    };

    this.camera.getPicture(options).then((imageData) => {
      this.data.document = 'data:image/jpeg;base64,' + imageData;
      this.documentChange = true;
    });
  }

  setBrowserFile(event: any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (): void => {
      this.data.document = '' + reader.result;
      this.documentChange = true;
    };
    reader.onerror = (error): void => {
      console.log('Error: ', error);
      this.toastService.sendMessage(200, 'Falha ao selecionar imagem');
    };
  }

  async clearImage() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja limpar a imagem selecionada?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            this.data.document = '';
            this.documentChange = false;
          }
        }
      ]
    });

    await alert.present();
  }

  create(loading: any, token: string) {
    this.serviceAddress.saveAddress(token, this.data).subscribe({
      next: (success: any) => {
        loading.dismiss();
        this.loading = false;
        this.toastService.sendMessage(success.status, success.body.message);
        this.storage.set(USER, success.body.user);
        if (success.status === 201) {
          this.hide();
        }
      },
      error: (error) => {
        loading.dismiss();
        this.toastService.sendMessage(error.status, error.error.message);
      },
      complete: () => console.log('complete')
    });
  }

  async verifyUpdate(loading: any, token: string) {
    if (!this.documentChange) {
      this.data.document = '';
      this.update(loading, token);
    } else {
      if (this.data.is_verified === 1) {
        const alert = await this.alertController.create({
          header: 'Atenção!',
          message: 'Você alterou um documento que já foi aprovado! Ao prosseguir, este seu local de atendimento será temporáriamente desabilitado até nossos profissionais analisarem a nova documentação. <br><br> Deseja prosseguir?',
          buttons: [
            {
              text: 'Não',
              role: 'cancel',
            }, {
              text: 'Sim',
              handler: async () => {
                this.update(loading, token);
              }
            }
          ]
        });

        await alert.present();
      }
    }
  }

  update(loading: any, token: string) {
    this.serviceAddress.updateAddress(token, this.data).subscribe((res: any) => {
      loading.dismiss();
      this.loading = false;
      this.toastService.sendMessage(res.status, res.body.message);
      this.storage.set(USER, res.body.user);
      if (res.status === 201) {
        this.hide();
      }

    }, err => {
      loading.dismiss();
      this.toastService.sendMessage(err.status, err.error.message);
    });
  }

  hide() {
    this.storage.remove(PULSE_ADDRESSES);
    this.storage.set(HIDE_PULSE_ADDRESSES, true);
    this.modalController.dismiss(true);
  }
}
