/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/utils/toastService';
import { Storage } from '@ionic/storage-angular';
import { TOKEN, USER } from 'src/main';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-set-attendance-radius',
  templateUrl: './set-attendance-radius.page.html',
  styleUrls: ['./set-attendance-radius.page.scss'],
})
export class SetAttendanceRadiusPage implements OnInit {
  loading = false;
  user = {
    professional: {
      attendance_radius: '',
      addresses: [],
      personal_address: {}
    }
  };

  selectedAddress = '';

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    private toastService: ToastService,
    private serviceProfile: ProfileService,
    public modalController: ModalController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.storage.get(USER).then((user) => {
      this.user = user;
      if (this.user.professional.personal_address) {
        this.user.professional.addresses.push(this.user.professional.personal_address);
      }
      if (this.user.professional.addresses) {
        this.user.professional.addresses.forEach(element => {
          if (element.use_radius) {
            this.selectedAddress = element.id;
          }
        });
      }
    });
  }

  save() {
    if (this.selectedAddress) {
      if (this.user.professional.attendance_radius) {
        this.alert();
      } else {
        this.toastService.sendMessage(200, 'Informe o raio de atendimento');
      }
    } else {
      this.toastService.sendMessage(200, 'Selecione um endereço de atendimento');
    }
  }

  async alert() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja alterar o raio de atendimento?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            const content = {
              address: this.selectedAddress,
              attendance_radius: this.user.professional.attendance_radius
            };

            this.storage.get(TOKEN).then(async (token) => {
              this.serviceProfile.updateAttendanceRadius(token, content).subscribe((res: any) => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.storage.set(USER, res.body.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
