/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  verifyCpf(document: string) {
    let cpf = document.replace('.', '');
    cpf = cpf.replace('.', '');
    cpf = cpf.replace('-', '');

    if (cpf) {
      let numbers; let digits; let sum; let i; let result; let equalDigits;
      equalDigits = 1;
      if (cpf.length < 11) {
        return null;
      }

      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = cpf.substring(0, 9);
        digits = cpf.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
          return false;
        }
        numbers = cpf.substring(0, 10);
        sum = 0;

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  verifyCnpj(document: any) {
    let cnpj = document.replace('/', '');
    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('-', '');

    if (cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj === '') { return false; }

      if (cnpj.length !== 14) { return false; }

      if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') { return false; }

      let len = cnpj.length - 2;
      let num = cnpj.substring(0, len);
      const digits = cnpj.substring(len);
      let sum = 0;
      let pos = len - 7;
      for (let i = len; i >= 1; i--) {
        sum += num.charAt(len - i) * pos--;
        if (pos < 2) { pos = 9; }
      }
      let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(0)) { return false; }

      len = len + 1;
      num = cnpj.substring(0, len);
      sum = 0;
      pos = len - 7;
      for (let i = len; i >= 1; i--) {
        sum += num.charAt(len - i) * pos--;
        if (pos < 2) { pos = 9; }
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(1)) {
        return false;
      }

      return true;
    }
    return null;
  }

  validateBirth(birthDate: string) {
    const hoje = new Date();
    const esteAno = hoje.getFullYear();

    let data = birthDate.replace('/', '');
    data = data.replace('/', '');

    const dia = data.substring(0, 2);
    const mes = data.substring(2, 4);
    const ano = data.substring(4, 8);

    if (Number(dia) > 0 && Number(dia) <= 31 && (Number(mes)) <= 12 && (Number(mes)) > 0 && Number(ano) < esteAno) {

      if ((Number(mes) === 2 && Number(dia) > 29) || (Number(mes) === 4 && Number(dia) > 30) || (Number(mes) === 6 && Number(dia) > 30) || (Number(mes) === 9 && Number(dia) > 30) || (Number(mes) === 11 && Number(dia) > 30)) {
        return false;

      } else {
        if (Number(dia) === 29 && Number(mes) === 2) {
          if (((Number(ano) % 4 === 0) && ((Number(ano) % 100 !== 0) || (Number(ano) % 400 === 0))) && Number(dia) === 29 && Number(mes) === 2) {
            return true;

          } else {
            return false;
          }
        } else {
          return true;
        }
      }

    } else {
      return false;
    }

  }

}
