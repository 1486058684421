import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { THEME } from 'src/main';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class ThemeDefault {
  renderer: Renderer2;

  constructor(
    private storage: Storage,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  enableDark() {
    this.renderer.addClass(this.document.body, 'dark-theme');
    this.storage.set(THEME, 'dark');
  }

  enableLight() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
    this.storage.remove(THEME);
  }

}
