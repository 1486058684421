/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

const PATH = 'professional/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  //GETS
  getProfile(token: string) {
    return this.http.get(API + PATH, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  getCategoryDocuments(token: string) {
    return this.http.get(API + PATH + '/requested-documents', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  getRatings(token: string, page: number) {
    return this.http.get(API + PATH + '/ratings?page=' + page, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  getRemainingTime(token: string) {
    return this.http.get(API + PATH + '/remaining-time', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  //POSTS
  updateDocuments(content: any, token: string) {
    return this.http.post(API + PATH + '/documents', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateAvatar(content, token: string) {
    return this.http.post(API + PATH + '/profile-picture', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  deleteAccount(token: string) {
    return this.http.get(API + PATH + '/delete-account', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  //UPDATES
  updatePassword(token: string, content: any) {
    return this.http.put(API + PATH + '/password', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  requestChangeEmailCode(token: string, content: any) {
    return this.http.post(API + PATH + '/request-email-validation-code', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateEmail(token: string, content: any) {
    return this.http.put(API + PATH + '/email', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateMobileNumber(token: string, content: any) {
    return this.http.put(API + PATH + '/mobile-number', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateAttendanceRadius(token: string, content: any) {
    return this.http.put(API + PATH + '/attendance-radius', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateContactMeans(token: string, content: any) {
    return this.http.put(API + PATH + '/contact-means', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateAuxiliaryEmails(token: string, content: any) {
    return this.http.put(API + PATH + '/auxiliary-emails', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateAbout(token: string, content: any) {
    return this.http.put(API + PATH + '/about', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateShowProfile(content: any, token: string) {
    return this.http.put(API + PATH + '/show-profile', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateReceiveServiceMails(content: any, token: string) {
    return this.http.put(API + PATH + '/receive-service-mails', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateReceivePromotionMails(content: any, token: string) {
    return this.http.put(API + PATH + '/receive-promotion-mails', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  seeProfile(token: string) {
    return this.http.get(API + PATH + '/see-profile', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

}
