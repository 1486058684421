import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/utils/toastService';
import { ONESIGNAL_APP_ID } from 'src/main';

const WINDOW: any = window as any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastService: ToastService
  ) { }

  start(idUser: number) {
    WINDOW.plugins.OneSignal.setAppId(ONESIGNAL_APP_ID);
    WINDOW.plugins.OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent) => {
      notificationReceivedEvent.complete(null);
      //const notification = notificationReceivedEvent.getNotification();
      //notificationReceivedEvent.complete(notification);
      //this.onPushReceived(notification.getBody());
    });

    this.removeExternalId();
    this.setExternalId(idUser.toString());
  }

  setExternalId(externalUserId: string) {
    WINDOW.plugins.OneSignal.setExternalUserId(externalUserId, (results) => {
      console.log('Results of setting external user id');
      console.log(results);
      if (results.push && results.push.success) {
        console.log('Results of setting external user id push status:');
        console.log(results.push.success);
      }
    }, err => {
      this.toastService.sendMessage(200, 'Falha ao adicionar id externo');
      console.log(err);
    });
  }

  removeExternalId() {
    WINDOW.plugins.OneSignal.removeExternalUserId((results) => {
      console.log('Results of removing external user id');
      console.log(results);
      if (results.push && results.push.success) {
        console.log('Results of removing external user id push status:');
        console.log(results.push.success);
      }
    }, err => {
      this.toastService.sendMessage(200, 'Falha ao remover id externo');
      console.log(err);
    });
  }

  end() {
    WINDOW.plugins.OneSignal.setAppId(ONESIGNAL_APP_ID);
    this.removeExternalId();
  }

  async onPushReceived(payload: any) {
    const msg = payload.body;
    this.toastService.sendMessage(200, JSON.stringify(msg));
  }

}
