/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Optional } from '@angular/core';
import { LoadingController, AlertController, IonRouterOutlet, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { SuggestCardsPage } from '../../modals/suggest-cards/suggest-cards.page';
import { ToastService } from 'src/app/utils/toastService';
import { PaymentTypeService } from 'src/app/services/payment-type/payment-type.service';
import { TOKEN, LOADING_ICON, HIDE_PULSE_PAYMENT_CARDS, PULSE_PAYMENT_CARDS } from 'src/main';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.page.html',
  styleUrls: ['./list-card.page.scss'],
})
export class ListCardPage implements OnInit {
  loading = false;
  loadingSpinner = false;
  showMessage = false;
  pixKey = '';

  paymentTypes = [];
  tempPaymentTypes = [];

  cards = [];
  tempCards = [];

  toSend = {
    cards: [],
    types: []
  };

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private servicePaymentType: PaymentTypeService,
    private navController: NavController,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
  }

  ngOnInit() {
    this.verifyMessage();
    this.loadPage();
  }

  loadPage() {
    this.loading = true;
    this.loadingSpinner = true;

    this.paymentTypes = [];
    this.tempPaymentTypes = [];
    this.cards = [];
    this.tempCards = [];

    this.storage.get(TOKEN).then((token) => {
      this.servicePaymentType.getPaymentTypes(token).subscribe((res: any) => {
        this.loading = false;
        this.loadingSpinner = false;

        if (res.status === 201) {
          this.paymentTypes = res.body.types;
          this.tempPaymentTypes = res.body.types;
          this.cards = res.body.cards;
          this.tempCards = res.body.cards;
          this.pixKey = res.body.pix_key;
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }

      }, err => {
        this.loading = false;
        this.loadingSpinner = false;
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  verify() {
    let isReady = true;

    this.paymentTypes.forEach(element => {
      if (element.name === 'Pix' && element.isChecked) {
        if (!this.pixKey) {
          isReady = false;
        }
      } else if (element.name === 'Pix' && !element.isChecked) {
        this.pixKey = '';
      }
    });

    if (isReady) {
      this.send();
    } else {
      this.toastService.sendMessage(200, 'Informe sua chave pix aleatória');
    }
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Você tem certeza que deseja salvar estes meios de pagamento?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();
            this.loading = true;

            const dataSend = {
              pix_key: this.pixKey,
              types: this.paymentTypes,
              cards: this.cards
            };

            this.storage.get(TOKEN).then((token) => {
              this.servicePaymentType.updateCards(dataSend, token).subscribe((res: any) => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.hide();
                  this.navController.back();
                }
              }, err => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  change(item: any, type: number) {
    if (type === 0) {
      this.paymentTypes.forEach(element => {
        if (element.id === item.id) {
          element.isChecked = !element.isChecked;
        }
      });
    } else if (type === 1) {
      this.cards.forEach(element => {
        if (element.id === item.id) {
          element.credit = !element.credit;
        }
      });
    } else if (type === 2) {
      this.cards.forEach(element => {
        if (element.id === item.id) {
          element.debt = !element.debt;
        }
      });
    }
  }

  verifyMessage() {
    this.storage.get(HIDE_PULSE_PAYMENT_CARDS).then(async (data) => {
      if (!data) {
        this.storage.get(PULSE_PAYMENT_CARDS).then(async (data2) => {
          if (data2) {
            this.showMessage = true;
          }
        });
      }
    });
  }

  hide() {
    this.storage.remove(PULSE_PAYMENT_CARDS);
    this.storage.set(HIDE_PULSE_PAYMENT_CARDS, true);
    this.showMessage = false;
  }

  async search(element: any) {
    const searchTerm = element.value;
    this.paymentTypes = this.paymentTypes;
    if (!searchTerm) {
      this.paymentTypes = this.tempPaymentTypes;
      return;
    } else {
      this.paymentTypes = this.paymentTypes.filter(data => {
        if (data.name && searchTerm) {
          return (data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
        }
      });
    }
  }

  async searchCards(element: any) {
    const searchTerm = element.value;
    this.cards = this.tempCards;
    if (!searchTerm) {
      this.cards = this.tempCards;
      return;
    } else {
      this.cards = this.cards.filter(data => {
        if (data.brand && searchTerm) {
          return (data.brand.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
        }
      });
    }
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: SuggestCardsPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      swipeToClose: true
    });

    return await modal.present();
  }

  refresh(event) {
    this.loading = true;
    setTimeout(() => {
      this.loadPage();
      event.target.complete();
    }, 1500);
  }

}
