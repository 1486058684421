/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable id-blacklist */
import { Component, OnInit, Optional } from '@angular/core';
import { AlertController, IonRouterOutlet, LoadingController, ModalController, Platform } from '@ionic/angular';
import { PaymentPlanService } from 'src/app/services/payment-plan/payment-plan.service';
import { ToastService } from 'src/app/utils/toastService';
import { Storage } from '@ionic/storage-angular';
import { DOCUMENT_CARD, DOCUMENT_NF, LOADING_ICON, TOKEN, USER } from 'src/main';
import { ValidatorService } from 'src/app/utils/ValidatorService';
import { NewCreditCardPage } from '../../profile/my-credit-cards/new-credit-card/new-credit-card.page';
import { ServiceContractPage } from '../service-contract/service-contract.page';

@Component({
  selector: 'app-change-plan',
  templateUrl: './change-plan.page.html',
  styleUrls: ['./change-plan.page.scss'],
})
export class ChangePlanPage implements OnInit {
  loading = false;
  sameNfDocument = false;
  checkContract = false;
  segment = '1';

  cards = [];
  paymentMethod = 'DEBIT';
  installments = '1';
  selectedPlan = '';
  selectedCard = '';
  planMembershipFee = '';
  planMonthsLong = '';
  planName = '';
  planPrice = '';
  planTotal = '';
  nfDocument = '';
  cvv = '';
  document = '';

  card = {
    brand: '',
    last_numbers: '',
    expire: ''
  };

  constructor(
    public toastService: ToastService,
    private paymentPlanService: PaymentPlanService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private storage: Storage,
    public platform: Platform,
    private validatorService: ValidatorService,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) { }

  ngOnInit() {
    this.refreshCards();
  }

  verify() {
    if (!this.selectedPlan) {
      this.toastService.sendMessage(200, 'Você precisa selecionar um plano de contratação');
      return;
    }

    if (!this.selectedCard) {
      this.toastService.sendMessage(200, 'Você precisa informar todos os dados de pagamento');
      return;
    }

    if (!this.document || !this.validatorService.verifyCpf(this.document)) {
      this.toastService.sendMessage(200, 'O documento do titular é inválido');
      return;
    }

    if (!this.nfDocument || !this.validatorService.verifyCpf(this.nfDocument)) {
      this.toastService.sendMessage(200, 'O documento para a nota fiscal é inválido');
      return;
    }

    if (!this.cvv) {
      this.toastService.sendMessage(200, 'Digite o CVV do cartão selecionado');
      return;
    }

    if (!this.checkContract) {
      this.toastService.sendMessage(200, 'É preciso aceitar os termos do contrato de prestação de serviços para continuar');
      return;
    }

    this.send();
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Você tem certeza que deseja contratar este plano?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            const content = {
              plan: this.selectedPlan,
              credit_card: this.selectedCard,
              document: this.document,
              cvv: this.cvv,
              installments: this.installments,
              nf_document: this.nfDocument,
              payment_method: this.paymentMethod
            };

            this.storage.get(TOKEN).then(async (token) => {
              this.paymentPlanService.update(content, token).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);
                if (res.status === 201) {
                  this.storage.set(USER, res.body.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  next(segment: string) {
    if (this.segment === '1') {
      if (!this.selectedCard) {
        this.toastService.sendMessage(200, 'É necessário selecionar um cartão');
        return;
      }

      this.storage.get(DOCUMENT_CARD).then((card) => {
        this.document = card;
      });

      this.storage.get(DOCUMENT_NF).then((nf) => {
        this.nfDocument = nf;
      });
    } else if (this.segment === '2') {
      if (!this.document || !this.validatorService.verifyCpf(this.document)) {
        this.toastService.sendMessage(200, 'O documento do titular é inválido');
        return;
      }

      if (!this.nfDocument || !this.validatorService.verifyCpf(this.nfDocument)) {
        this.toastService.sendMessage(200, 'O documento para a nota fiscal é inválido');
        return;
      }

      if (!this.cvv) {
        this.toastService.sendMessage(200, 'É necessário informar o cvv para prosseguir');
        return;
      }

      if (!this.installments) {
        this.toastService.sendMessage(200, 'É necessário selecionar o parcelamento para prosseguir');
        return;
      }

      this.storage.set(DOCUMENT_CARD, this.document);
      this.storage.set(DOCUMENT_NF, this.nfDocument);
    }

    this.segment = segment;
  }

  async openNewCardModal() {
    const modal = await this.modalController.create({
      component: NewCreditCardPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((content) => {
      if (content.data !== undefined) {
        this.refreshCards();
      }
    });

    return await modal.present();
  }

  changeNfDocument(event: any) {
    if (!event.detail.checked) {
      this.nfDocument = '';
    } else {
      this.nfDocument = this.document;
    }
  }

  changePaymentMethod(ev: any) {
    if (ev.detail.value === 'DEBIT') {
      this.installments = '1';
    }
  }

  setCard() {
    this.cards.forEach(element => {
      if(element.hash === this.selectedCard){
        this.card.brand = element.brand;
        this.card.last_numbers = element.last_numbers;
        this.card.expire = element.expire;
      }
    });
  }
  back(segment: string) {
    this.segment = segment;
  }

  refreshCards() {
    this.storage.get(USER).then(async (user) => {
      this.cards = [];

      let count = 0;
      user.credit_cards.forEach(element => {

        if (count === 0) {
          this.selectedCard = element.hash;
          this.setCard();
        }

        this.cards.push(element);
        count++;
      });

    });
  }

  async setModal(page: number) {
    let componentModal = null;

    if (page === 1) {
      componentModal = ServiceContractPage;
    }

    const modal = await this.modalController.create({
      component: componentModal,
      mode: 'ios',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((content) => {
      const data = content.data;

      if (data !== undefined) {

      }
    });

    return await modal.present();
  }
}
