/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

const PATH = 'professional/credit-cards';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  list(token: string, page: number) {
    return this.http.get(API + PATH + '/list?page=' + page, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  select(token: string, hash: string) {
    return this.http.get(API + PATH + '/select?hash=' + hash, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  create(token: string, content: any) {
    return this.http.post(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  delete(token: string, hash: number) {
    return this.http.delete(API + PATH + '/' + hash, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }
}
