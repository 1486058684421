/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/utils/toastService';
import { Storage } from '@ionic/storage-angular';
import { TOKEN, USER } from 'src/main';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-change-professional-emails',
  templateUrl: './change-professional-emails.page.html',
  styleUrls: ['./change-professional-emails.page.scss'],
})
export class ChangeProfessionalEmailsPage implements OnInit {
  loading = false;
  user = {
    professional: {
      auxiliary_mails: {
      }
    }
  };

  data: {
    email_1: '';
    email_2: '';
    email_3: '';
    email_4: '';
    email_5: '';
  };

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    private toastService: ToastService,
    private serviceProfile: ProfileService,
    public modalController: ModalController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.storage.get(USER).then(async (user) => {
      this.user = user;
      if (user.professional.auxiliary_mails) {
        this.data = user.professional.auxiliary_mails;
      }
    });
  }

  async save() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja alterar os emails auxiliares?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            this.storage.get(TOKEN).then((token) => {
              this.serviceProfile.updateAuxiliaryEmails(token, this.data).subscribe((res: any) => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.user.professional.auxiliary_mails = this.data;
                  this.storage.set(USER, this.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
