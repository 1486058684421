import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const ITERATIONS = 999;
const KEY_SIZE = 64 / 8;
const SECRET_KEY = '0123456789abcdef0123456789abcdef';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {

    constructor() { }

    public encryptAll(data): any {
        // data = JSON.parse(data);
        if (Array.isArray(data) && data.length > 0) {
            data.forEach((element, index) => {
                data[index] = this.encrypt(element);
            })
        } else if (typeof data === 'object' && data !== null) {

            for (let key in data) {
                if (Array.isArray(data[key])) {
                    data[key] = this.encrypt(data[key]);
                } else if (typeof data[key] === 'object' && data[key] !== null) {
                    data[key] = this.encrypt(data[key]);
                } else {
                    data[key] = this.encrypt(data[key]);
                }
            }
        }

        return data;
    }

    public decryptAll(data): any {

        if (Array.isArray(data) && data.length > 0) {
            data.forEach((element, index) => {
                data[index] = this.decrypt(element);
            })
        } else if (typeof data === 'object' && data !== null) {
            for (let key in data) {

                if (Array.isArray(data[key])) {
                    data[key] = this.decrypt(data[key]);
                } else if (typeof data[key] === 'object' && data[key] !== null) {
                    data[key] = this.decrypt(data[key]);
                } else {
                    data[key] = this.decrypt(data);
                }
            }
        }
        return data;
    }

    public encrypt(text): string {

        if (typeof text != 'string') {
            text = JSON.stringify(text);
        }

        const salt = CryptoJS.lib.WordArray.random(256);
        const iv = CryptoJS.lib.WordArray.random(16);

        const key = CryptoJS.PBKDF2(SECRET_KEY, salt, {
            hasher: CryptoJS.algo.SHA512,
            keySize: KEY_SIZE,
            iterations: ITERATIONS
        });

        const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });

        const data = {
            ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
            salt: CryptoJS.enc.Hex.stringify(salt),
            iv: CryptoJS.enc.Hex.stringify(iv)
        }
        return JSON.stringify(data);
    }

    public decrypt(obj): string {
        let value;

        const encrypted = obj.ciphertext;
        const salt = CryptoJS.enc.Hex.parse(obj.salt);
        const iv = CryptoJS.enc.Hex.parse(obj.iv);

        const key = CryptoJS.PBKDF2(SECRET_KEY, salt, {
            hasher: CryptoJS.algo.SHA512,
            keySize: KEY_SIZE,
            iterations: ITERATIONS
        });
        const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
        value = decrypted.toString(CryptoJS.enc.Utf8);

        return value;
    }

}