/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { environment } from 'src/environments/environment';
import { API } from 'src/main';

const PATH = 'professional/payment-types';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  getPaymentTypes(token: string) {
    return this.http.get(API + PATH + '/list', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateCards(content: any, token: string) {
    return this.http.put(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

}
