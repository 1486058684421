import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TOAST_POSITION, TOAST_MODE, TOAST_TIME, TOAST_ICON } from 'src/main';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController
  ) { }

  sendMessage(code: number, message: string) {
    if (code === 201 || code === 200) {
      this.toast(message, 'dark');
    } else if (code === 422) {
      this.toast(message, 'warning');
    } else if (code === 401) {
      this.toast(message, 'secondary');
    } else if (code === 404 || code === 405) {
      this.toast('Ação não reconhecida', 'danger');
    } else {
      this.toast('Tente novamente mais tarde', 'danger');
    }
  }

  async toast(msg, type) {
    const toast = await this.toastController.create({
      message: msg,
      color: type,
      position: TOAST_POSITION,
      mode: TOAST_MODE,
      duration: TOAST_TIME,
      buttons: [
        {
          icon: TOAST_ICON,
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

}
