import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ToastService } from 'src/app/utils/toastService';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { USER, TOKEN } from 'src/main';

@Component({
  selector: 'app-change-about',
  templateUrl: './change-about.page.html',
  styleUrls: ['./change-about.page.scss'],
})
export class ChangeAboutPage implements OnInit {
  loading = false;
  user = {
    professional: {
      about: ''
    }
  };

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private serviceProfile: ProfileService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.storage.get(USER).then(async (user) => {
      this.user = user;
    });
  }

  save() {
    if (this.user.professional.about && this.user.professional.about.length > 9) {
      this.alert();
    } else {
      this.toastService.sendMessage(200, 'Sua apresentação deve conter ao menos 10 caracteres');
    }
  }

  async alert() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja alterar sua apresentação?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            const content = {
              about: this.user.professional.about
            };

            this.storage.get(TOKEN).then(async (token) => {
              this.serviceProfile.updateAbout(token, content).subscribe((res: any) => {
                console.log(res);
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.storage.set(USER, this.user);
                  this.modalController.dismiss(true);
                }
              }, err => {
                console.log(err);
                this.loading = false;
                loading.dismiss();

                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
