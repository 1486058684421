import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { THEME } from 'src/main';
import { ThemeDefault } from './utils/themeDefault';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private storage: Storage,
    private themeDefault: ThemeDefault,
  ) {
    this.platform.ready().then(() => {
      this.storage.create();
      this.loadTheme();
    });
  }

  async loadTheme() {
    this.storage.get(THEME).then((theme) => {
      if (theme) {
        if (theme === 'dark') {
          this.themeDefault.enableDark();
        } else {
          this.themeDefault.enableLight();
        }
      } else {
        this.themeDefault.enableLight();
      }
    });
  }

}
