/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable id-blacklist */
import { Component, OnInit } from '@angular/core';
import { Camera, CameraOptions, PictureSourceType } from '@awesome-cordova-plugins/camera/ngx';
import { LoadingController, AlertController, ActionSheetController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AddressService } from 'src/app/services/address/address.service';
import { ToastService } from 'src/app/utils/toastService';
import { TOKEN } from 'src/main';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss'],
})
export class GalleryPage implements OnInit {
  address = {
    id: 0,
    name: '',
    district: '',
    neighborhood: '',
    street: '',
    number: '',
    city: '',
    state: '',
    gallery: []
  };

  gallery = [];
  isCordova = true;

  constructor(
    private camera: Camera,
    private storage: Storage,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private toastService: ToastService,
    private serviceAddress: AddressService,
    private platform: Platform
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }
  }

  ngOnInit() {
    this.address.gallery.forEach(element => {
      this.gallery.push(element);
    });

    if (this.gallery.length < 5) {
      this.addItens();
    }
  }

  addItens() {
    for (let i = this.gallery.length; i < 5; i++) {
      this.gallery.push({
        id: 0,
        status: -1,
        image: null,
        changed: false
      });
    }
  }

  async selectImage(item: any) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Selecione o local da imagem',
      cssClass: 'my-action-sheet',
      buttons: [{
        text: 'Galeria',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Camera',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancelar',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();
  }

  setBrowserFile(item: any, event: any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (): void => {
      item.image = reader.result;
      item.changed = true;
    };
    reader.onerror = (error): void => {
      console.log('Error: ', error);
      this.toastService.sendMessage(200, 'Falha ao selecionar imagem');
    };
  }

  async takePicture(item: any, sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 80,
      sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
    };

    this.camera.getPicture(options).then((imageData) => {
      item.image = 'data:image/jpeg;base64,' + imageData;
      item.changed = true;
    });
  }

  async doUpload(item: any) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja enviar esta imagem para aprovação?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            const content = {
              address: this.address.id,
              id: item.id,
              image: item.image
            };

            this.storage.get(TOKEN).then((token) => {
              this.serviceAddress.addGalleryImage(token, content).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  item.status = 0;
                  item.changed = false;
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
