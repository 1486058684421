/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */
import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { AddLocalityPage } from '../../modals/add-locality/add-locality.page';
import { LoadingController, AlertController, ModalController, IonRouterOutlet, IonInfiniteScroll, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AddressService } from 'src/app/services/address/address.service';
import { ToastService } from 'src/app/utils/toastService';
import { USER, TOKEN, LOADING_ICON, HIDE_PULSE_ADDRESSES, PULSE_ADDRESSES } from 'src/main';
import { GalleryPage } from './gallery/gallery.page';

@Component({
  selector: 'app-list-locality',
  templateUrl: './list-locality.page.html',
  styleUrls: ['./list-locality.page.scss'],
})
export class ListLocalityPage implements OnInit {
  @ViewChild('IonInfiniteScroll', { read: IonInfiniteScroll, static: true }) infiniteScroll: IonInfiniteScroll;
  loading = false;
  pageNo = 1;
  showMessage = false;
  isCordova = true;

  addresses = [];
  user = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_picture: '',
    professional: {
      is_verified: '',
      register_number: '',
      category: 0,
      categories: {
        name: ''
      }
    }
  };

  havePlan = -1;

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private serviceAddress: AddressService,
    private platform: Platform,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }

    this.storage.get(USER).then(async (user) => {
      this.user = user;

      if (user.professional.last_payment_plan) {
        this.havePlan = user.professional.last_payment_plan.active;
      }
    });
  }

  ngOnInit() {
    this.verifyMessage();
    this.loadPage(false);
  }

  loadPage(infinite: boolean) {
    if (!infinite) {
      this.loading = true;
      this.pageNo = 1;
      this.addresses = [];
      if (this.infiniteScroll) {
        this.infiniteScroll.disabled = false;
      }
    }

    this.storage.get(TOKEN).then((token) => {
      this.serviceAddress.getAddresses(token, this.pageNo).subscribe((res: any) => {
        this.loading = false;
        if (res.status === 201) {
          if (infinite) { this.infiniteScroll.complete(); }

          if (res.body.length === 0) {
            this.infiniteScroll.disabled = true;
          } else {
            this.pageNo++;

            res.body.forEach(element => {
              this.addresses.push(element);
            });
          }
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        if (infinite) { this.infiniteScroll.complete(); }
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async delete(id: number) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja apagar este endereço?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();
            this.loading = true;

            this.storage.get(TOKEN).then((token) => {
              this.serviceAddress.deleteAddress(token, id).subscribe((res: any) => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  this.storage.set(USER, res.body.user);
                  this.loadPage(false);
                }
              }, err => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  verifyMessage() {
    this.storage.get(HIDE_PULSE_ADDRESSES).then(async (data) => {
      if (!data) {
        this.storage.get(PULSE_ADDRESSES).then(async (data2) => {
          if (data2) {
            this.showMessage = true;
          }
        });
      }
    });
  }

  hide() {
    this.storage.remove(PULSE_ADDRESSES);
    this.storage.set(HIDE_PULSE_ADDRESSES, true);
    this.showMessage = false;
  }

  refresh(event) {
    setTimeout(() => {
      this.loadPage(false);
      event.target.complete();
    }, 1500);
  }

  doInfinite() {
    this.loadPage(true);
  }

  async openGallery(item) {
    const modal = await this.modalController.create({
      component: GalleryPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        address: item
      }
    });

    return await modal.present();
  }

  async openModal(data: any) {
    if (!data) {
      data = {};
    }

    const modal = await this.modalController.create({
      component: AddLocalityPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        data: data
      }
    });

    modal.onDidDismiss().then((content) => {
      const res = content.data;

      if (res !== undefined) {
        this.loadPage(false);
        this.hide();
      }
    });

    return await modal.present();
  }

}
