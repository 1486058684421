/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

const PATH = 'professional/insurances';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  getMyInsurances(token: string, search: string, page: number) {
    return this.http.get(API + PATH + '/list?page=' + page + '&search=' + search, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateInsurance(token: string, content: any) {
    return this.http.put(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  changeStatus(token: string, content: any) {
    return this.http.put(API + PATH + '/change-status', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

}
