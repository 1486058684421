import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { SuggestService } from 'src/app/services/suggest/suggest.service';
import { ToastService } from 'src/app/utils/toastService';
import { LOADING_ICON, TOKEN } from 'src/main';

@Component({
  selector: 'app-suggest-cards',
  templateUrl: './suggest-cards.page.html',
  styleUrls: ['./suggest-cards.page.scss'],
})
export class SuggestCardsPage implements OnInit {
  loading = false;

  suggest = '';

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public modalController: ModalController,
    private serviceSuggest: SuggestService,
    private storage: Storage,
    public alertController: AlertController
  ) { }

  ngOnInit() {
  }

  verify() {
    if (this.suggest.length >= 5) {
      this.send();
    } else {
      this.toastService.sendMessage(200, 'A sugestão precisa conter ao menos 5 caracteres');
    }
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Você tem certeza que deseja enviar esta sugestão?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();
            this.loading = true;

            const content = {
              suggest: this.suggest
            };

            this.storage.get(TOKEN).then((token) => {
              this.serviceSuggest.suggestCards(token, content).subscribe(async (res: any) => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(res.status, res.body.message);
                if (res.status === 201) {
                  this.modalController.dismiss(true);
                }

              }, err => {
                loading.dismiss();
                this.loading = false;
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
