/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController, ActionSheetController, Platform } from '@ionic/angular';
import { Camera, CameraOptions, PictureSourceType } from '@awesome-cordova-plugins/camera/ngx';
import { Storage } from '@ionic/storage-angular';
import { ToastService } from 'src/app/utils/toastService';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { TOKEN } from 'src/main';

@Component({
  selector: 'app-send-documents',
  templateUrl: './send-documents.page.html',
  styleUrls: ['./send-documents.page.scss'],
})
export class SendDocumentsPage implements OnInit {
  loading = false;
  documentsRequired = [];
  isCordova = true;

  constructor(
    private camera: Camera,
    private storage: Storage,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private serviceProfile: ProfileService,
    private toastService: ToastService,
    private platform: Platform
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }
  }

  ngOnInit() {
    this.loadRequiredDocuments();
  }

  async loadRequiredDocuments() {
    this.loading = true;

    this.storage.get(TOKEN).then((token) => {
      this.serviceProfile.getCategoryDocuments(token).subscribe((res: any) => {
        this.loading = false;

        if (res.status === 201) {
          this.documentsRequired = res.body;
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async selectImage(item: any) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Selecione o local da imagem',
      cssClass: 'my-action-sheet',
      buttons: [{
        text: 'Galeria',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Camera',
        handler: () => {
          this.takePicture(item, this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancelar',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();
  }

  async takePicture(item: any, sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 80,
      sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
    };

    this.camera.getPicture(options).then((imageData) => {
      item.url = "data:image/jpeg;base64," + imageData;
      item.changed = true;
    });
  }

  setBrowserFile(item: any, event: any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (): void => {
      item.url = reader.result;
      item.changed = true;
    };
    reader.onerror = (error): void => {
      console.log('Error: ', error);
      this.toastService.sendMessage(200, 'Falha ao selecionar imagem');
    };
  }

  async doUpload(item: any) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Tem certeza que deseja enviar este documento para aprovação?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            const content = {
              id: item.id,
              document: item.url
            };

            this.storage.get(TOKEN).then((token) => {
              this.serviceProfile.updateDocuments(content, token).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);

                if (res.status === 201) {
                  item.status = 0;
                  item.changed = false;
                }
              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  verify(item: any) {
    if (item.changed) {
      this.doUpload(item);
    } else {
      this.toastService.sendMessage(200, 'É necessário selecionar um documento!');
    }
  }

}
