/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from 'src/app/services/guard/authentication.service';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/utils/toastService';
import { SHOW_STEPS, TOKEN, USER } from 'src/main';
import { EditProfessionalProfilePage } from '../../profile/edit-professional-profile/edit-professional-profile.page';
import { ListCardPage } from '../../profile/list-card/list-card.page';
import { ListInsurancePage } from '../../profile/list-insurance/list-insurance.page';
import { ListLocalityPage } from '../../profile/list-locality/list-locality.page';
import { ListSchedulePage } from '../../profile/list-schedule/list-schedule.page';
import { PlansPage } from '../plans/plans.page';
import { SendDocumentsPage } from '../send-documents/send-documents.page';
import { AnnuityPage } from '../annuity/annuity.page';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.page.html',
  styleUrls: ['./steps.page.scss'],
})
export class StepsPage implements OnInit {
  loading = false;

  professional = {
    is_verified: 0,
    show_profile: 0,
    insurances: [],
    payment_types: [],
    addresses: [],
    timelines: [],
    services: [],
    last_payment_plan: {
      active: 0
    }
  };

  constructor(
    public toastService: ToastService,
    private userService: UserService,
    private auth: AuthenticationService,
    public modalController: ModalController,
    private storage: Storage
  ) {


  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.storage.get(USER).then(async (user) => {
      if (user) {
        this.storage.get(TOKEN).then(async (token) => {
          if (token) {
            this.userService.refresh(token).subscribe((res: any) => {
              this.loading = false;
              if (res.status === 201) {
                if (res.body) {
                  this.storage.set(USER, res.body);
                  this.professional = res.body.professional;
                }
              } else {
                this.toastService.sendMessage(res.status, res.body.message);
                this.auth.logout();
              }
            }, err => {
              this.loading = false;
              this.toastService.sendMessage(err.status, err.error.message);
              this.auth.logout();
            });
          } else {
            this.loading = false;
            this.auth.logout();
          }
        });
      } else {
        this.loading = false;
        this.auth.logout();
      }
    });
  }

  hide() {
    this.storage.set(SHOW_STEPS, true);
    this.modalController.dismiss();
  }

  async setModal(page: number) {
    let component = null;

    if (page === 0) {
      if (this.professional.last_payment_plan) {
        if (this.professional.last_payment_plan.active === 1) {
          this.toastService.sendMessage(200, 'Você já possui um plano de contratação');
          return;
        } else if (this.professional.last_payment_plan.active === 0) {
          this.toastService.sendMessage(200, 'Aguarde nosso sistema confirmar o pagamento do plano de contratação para prosseguir');
          return;
        } else if (this.professional.last_payment_plan.active === 3) {
          component = AnnuityPage;
        }
      } else {
        component = PlansPage;
      }
    } else if (page === 1) {
      if (this.professional.last_payment_plan) {
        if (this.professional.last_payment_plan.active === 1) {
          component = SendDocumentsPage;
        } else if (this.professional.last_payment_plan.active === 0) {
          this.toastService.sendMessage(200, 'Aguarde nosso sistema confirmar o pagamento do plano de contratação para prosseguir');
          return;
        } else if (this.professional.last_payment_plan.active === 3) {
          this.toastService.sendMessage(200, 'Renove o plano de contratação para prosseguir');
          return;
        }
      } else {
        this.toastService.sendMessage(200, 'Você precisa selecionar um plano de contratação antes de prosseguir');
        return;
      }

    } else if (page === 2) {
      component = ListLocalityPage;
    } else if (page === 3) {
      component = ListSchedulePage;
    } else if (page === 4) {
      component = ListCardPage;
    } else if (page === 5) {
      component = ListInsurancePage;
    } else if (page === 6) {
      component = EditProfessionalProfilePage;
    }

    const modal = await this.modalController.create({
      component,
      mode: 'ios',
      cssClass: 'steps-redirect-modal',
      swipeToClose: true
    });

    modal.onDidDismiss().then(() => {
      this.storage.get(USER).then(async (user) => {
        this.professional = user.professional;
      });
    });

    return await modal.present();
  }

}
