import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

export const MAPS_KEY = environment.GOOGLE_MAPS_API_KEY;
export const ONESIGNAL_APP_ID = environment.ONESIGNAL_APP_ID;
export const JITSI_APP_ID = environment.JITSI_APP_ID;
export const JITSI_URL = 'https://8x8.vc';

export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const SPLASH = 'SPLASH';
export const THEME = 'THEME';
export const LOGIN = 'LOGIN';
export const VERIFICATION = 'VERIFICATION';
export const LOCATION = 'LOCATION';
export const LOCATION_LAT = 'LOCATION_LAT';
export const LOCATION_LONG = 'LOCATION_LONG';
export const DOCUMENT_CARD = 'PROFESSIONAL_DOCUMENT_CARD';
export const DOCUMENT_NF = 'PROFESSIONAL_DOCUMENT_NF';

export const FIRST_USE = 'FIRST_USE';
export const SHOW_STEPS = 'SHOW_STEPS';

export const PULSE_SERVICES = 'PULSE_SERVICES';
export const PULSE_ADDRESSES = 'PULSE_ADDRESSES';
export const PULSE_SCHEDULES = 'PULSE_SCHEDULES';
export const PULSE_PAYMENT_CARDS = 'PULSE_PAYMENT_CARDS';
export const PULSE_INSURANCES = 'PULSE_INSURANCES';
export const PULSE_DOCUMENTS = 'PULSE_DOCUMENTS';

export const HIDE_PULSE_SERVICES = 'HIDE_PULSE_SERVICES';
export const HIDE_PULSE_ADDRESSES = 'HIDE_PULSE_ADDRESSES';
export const HIDE_PULSE_SCHEDULES = 'HIDE_PULSE_SCHEDULES';
export const HIDE_PULSE_PAYMENT_CARDS = 'HIDE_PULSE_PAYMENT_CARDS';
export const HIDE_PULSE_INSURANCES = 'HIDE_PULSE_INSURANCES';
export const HIDE_STEPS = 'HIDE_STEPS';

export const BASE = environment.URL_BASE;
export const API = environment.URL_BASE + '/api/';
export const TOAST_POSITION = 'top';
export const TOAST_MODE = 'ios';
export const TOAST_TIME = 6000;
export const TOAST_ICON = 'chevron-up';
export const LOADING_ICON = 'circular';
export const VIDEO_MUTED = '';
