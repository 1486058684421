/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Optional } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ToastService } from 'src/app/utils/toastService';
import { TOKEN } from 'src/main';
import { } from 'swiper';
import SwiperCore, { Autoplay, Zoom, SwiperOptions, Pagination, Thumbs } from 'swiper';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { NewsDetailsPage } from '../../news/news-details/news-details.page';

SwiperCore.use([Autoplay, Zoom, Pagination, Thumbs]);
@Component({
  selector: 'app-see-my-profile',
  templateUrl: './see-my-profile.page.html',
  styleUrls: ['./see-my-profile.page.scss'],
})
export class SeeMyProfilePage implements OnInit {
  loading = true;

  thumbsSwiper: any;

  profile = {
    id: 0,
    about: '',
    profile_picture: '',
    name: '',
    last_name: '',
    services: [],
    addresses: [],
    insurances: [],
    register_number: '',
    category: '',
    payment_types: [],
    news: [],
    establishment: {
      cnpj: '',
      name: '',
      last_name: '',
      city: '',
      state: '',
      profile_picture: ''
    }
  };

  config: SwiperOptions = {
    autoplay: { delay: 6000 },
    loop: true,
    autoHeight: true,
    centeredSlides: true,
    zoom: true,
    pagination: {
      dynamicBullets: true
    }
  };

  constructor(
    public toastService: ToastService,
    private serviceProfile: ProfileService,
    private storage: Storage,
    public modalController: ModalController,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
  }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.loading = true;
    this.storage.get(TOKEN).then(async (token) => {
      this.serviceProfile.seeProfile(token).subscribe((res: any) => {
        this.loading = false;

        if (res.status === 201) {
          this.profile = res.body;
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }
      }, err => {
        this.loading = false;
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async openNewsModal(item: any) {
    const modal = await this.modalController.create({
      component: NewsDetailsPage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: true,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        data: item
      }
    });

    return await modal.present();
  }

}
