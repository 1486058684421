/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'src/app/utils/headerService';
import { API } from 'src/main';

const PATH = 'professional/addresses';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  getAddresses(token: string, page: number) {
    return this.http.get(API + PATH + '/list?page=' + page, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  saveAddress(token: string, content: any) {
    return this.http.post(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  updateAddress(token: string, content: any) {
    return this.http.put(API + PATH, JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  deleteAddress(token: string, id: number) {
    return this.http.delete(API + PATH + '/' + id, { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  savePersonalAddress(token: string, content: any) {
    return this.http.put(API + PATH + '/personal', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  addGalleryImage(token: string, content: any) {
    return this.http.post(API + PATH + '/gallery', JSON.stringify(content), { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  verifyCEP(cep: string) {
    return this.http.get('https://viacep.com.br/ws/' + cep + '/json/');
  }

}
