/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { AddSchedulePage } from '../../modals/add-schedule/add-schedule.page';
import { LoadingController, AlertController, ModalController, IonInfiniteScroll, IonRouterOutlet, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ToastService } from 'src/app/utils/toastService';
import { TimelineService } from 'src/app/services/timeline/timeline.service';
import { TOKEN, LOADING_ICON, HIDE_PULSE_SCHEDULES, PULSE_SCHEDULES, USER } from 'src/main';

@Component({
  selector: 'app-list-schedule',
  templateUrl: './list-schedule.page.html',
  styleUrls: ['./list-schedule.page.scss'],
})
export class ListSchedulePage implements OnInit {
  @ViewChild('IonInfiniteScroll', { read: IonInfiniteScroll, static: true }) infiniteScroll: IonInfiniteScroll;
  loading = false;
  pageNo = 1;
  showMessage = false;

  segment = '1';
  schedulesOnline = [];
  schedulesHome = [];
  schedulesOffice = [];
  isCordova = true;

  user = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_picture: '',
    professional: {
      addresses: [],
      attendance_radius: '',
      can_do_online_attendance: '',
      is_verified: '',
      register_number: '',
      categories: {
        name: ''
      },
      payment_plan_active: {
        status: 0
      }
    }
  };

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private serviceTimeline: TimelineService,
    private platform: Platform,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
    if (!this.platform.is('cordova')) {
      this.isCordova = false;
    }

    this.storage.get(USER).then(async (user) => {
      this.user = user;

      if (!this.user.professional.can_do_online_attendance) {
        this.segment = '2';
      }
    });
  }

  ngOnInit() {
    this.verifyMessage();
    this.loadPage(false);
  }

  loadPage(infinite: boolean) {
    if (!infinite) {
      this.loading = true;
      this.pageNo = 1;
      this.schedulesOnline = [];
      this.schedulesHome = [];
      this.schedulesOffice = [];
      if (this.infiniteScroll) {
        this.infiniteScroll.disabled = false;
      }
    }

    this.storage.get(TOKEN).then((token) => {
      this.serviceTimeline.getSchedules(token, this.pageNo).subscribe((res: any) => {
        this.loading = false;

        if (res.status === 201) {
          if (infinite) { this.infiniteScroll.complete(); }

          if (res.body.online.length === 0 && res.body.office.length === 0 && res.body.home.length === 0) {
            this.infiniteScroll.disabled = true;
          } else {
            this.pageNo++;

            res.body.online.forEach(element => {
              this.schedulesOnline.push(element);
            });

            res.body.office.forEach(element => {
              this.schedulesOffice.push(element);
            });

            res.body.home.forEach(element => {
              this.schedulesHome.push(element);
            });
          }
        } else {
          this.toastService.sendMessage(res.status, res.body.message);
        }

      }, err => {
        this.loading = false;
        if (infinite) { this.infiniteScroll.complete(); }
        this.toastService.sendMessage(err.status, err.error.message);
      });
    });
  }

  async openModal(data: any) {
    if (!data) {
      if (this.segment === '1') {
        data = {
          type: 'online'
        };
      } else if (this.segment === '2') {
        data = {
          type: 'home'
        };
      } else if (this.segment === '3') {
        data = {
          type: 'office'
        };
      } else {
        data = {};
      }
    }

    const modal = await this.modalController.create({
      component: AddSchedulePage,
      mode: 'ios',
      cssClass: 'background-shadow',
      swipeToClose: false,
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      componentProps: {
        data
      }
    });

    modal.onDidDismiss()
      .then((content) => {
        const res = content.data;

        if (res !== undefined) {
          this.loadPage(false);
          this.hide();
        }
      });

    return await modal.present();
  }

  async alert(item: any) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja alterar a disponibilidade deste horário?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
            });

            await loading.present();

            const content = {
              id: item.id
            };
            this.storage.get(TOKEN).then(async (token) => {
              this.serviceTimeline.updateScheduleStatus(content, token).subscribe((res: any) => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);
                if (res.status === 201) {
                  item.active = !item.active;
                }

              }, err => {
                this.loading = false;
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async delete(id: number) {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Deseja apagar este horário?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();

            this.storage.get(TOKEN).then((token) => {
              this.serviceTimeline.deleteSchedule(id, token).subscribe((res: any) => {
                loading.dismiss();
                this.toastService.sendMessage(res.status, res.body.message);
                if (res.status === 201) {
                  this.loadPage(false);
                }

              }, err => {
                loading.dismiss();
                this.toastService.sendMessage(err.status, err.error.message);
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  verifyMessage() {
    this.storage.get(HIDE_PULSE_SCHEDULES).then(async (data) => {
      if (!data) {
        this.storage.get(PULSE_SCHEDULES).then(async (data2) => {
          if (data2) {
            this.showMessage = true;
          }
        });
      }
    });
  }

  hide() {
    this.storage.remove(PULSE_SCHEDULES);
    this.storage.set(HIDE_PULSE_SCHEDULES, true);
    this.showMessage = false;
  }

  refresh(event) {
    this.loading = true;
    setTimeout(() => {
      this.loadPage(false);
      event.target.complete();
    }, 1500);
  }

  doInfinite() {
    this.loadPage(true);
  }

}
